import React from 'react';
import { css } from '@emotion/react';
import { HashLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
`;

const LoadingSpinner = () => {
  return (
      <HashLoader color={'#F6921a'} css={override} size={50} />
  );
};

export default LoadingSpinner;
