import React, { useState, useEffect, useMemo, useCallback  } from 'react';

const logoURL = process.env.REACT_APP_ENV === 'development' ? process.env.REACT_APP_URL_DEVELOPMENT + ':' + process.env.REACT_APP_PORT_CLIENT : process.env.REACT_APP_URL_CLIENT;

const Partners = () => {
  const [currentBanners, setCurrentBanners] = useState([]);

  const defaultBanners = useMemo(() => [
    {
      logoUrl: `${logoURL}/images/partners/bitcoin.svg`,
      partnerName: "bitcoin_org",
    },
    {
      logoUrl: `${logoURL}/images/partners/lnbits.svg`,
      partnerName: "lnbits_com",
    },
    {
      logoUrl: `${logoURL}/images/partners/voltage.svg`,
      partnerName: "voltage_cloud",
    },
    {
      logoUrl: `${logoURL}/images/partners/tor.svg`,
      partnerName: "torproject_org",
    },
    {
      logoUrl: `${logoURL}/images/partners/tails.svg`,
      partnerName: "tails_net",
    },
    {
      logoUrl: `${logoURL}/images/partners/electrum.svg`,
      partnerName: "electrum_org",
    },
    {
      logoUrl: `${logoURL}/images/partners/seedsigner.svg`,
      partnerName: "seedsigner_com",
    },
    {
      logoUrl: `${logoURL}/images/partners/njalla.svg`,
      partnerName: "njal.la",
    },
    {
      logoUrl: `${logoURL}/images/partners/1984.svg`,
      partnerName: "1984_hosting",
    },
  ], []);

  const otherPartners = useMemo(() => [
    {
      logoUrl: `${logoURL}/images/friends/mullvad.svg`,
    },
    {
      logoUrl: `${logoURL}/images/friends/bisq.svg`,
    },
    {
      logoUrl: `${logoURL}/images/friends/debian.svg`,
    },
    {
      logoUrl: `${logoURL}/images/friends/hrf.svg`,
    },
    {
      logoUrl: `${logoURL}/images/friends/raspberrypi.svg`,
    },
  ], []);

  const rotateBanners = useCallback(() => {
    let previousDefaultIndex;
    let previousOtherIndex;

    const interval = setInterval(() => {
      let randomDefaultIndex;
      let randomOtherIndex;

      do {
        randomDefaultIndex = Math.floor(Math.random() * defaultBanners.length);
        randomOtherIndex = Math.floor(Math.random() * otherPartners.length);
      } while (randomDefaultIndex === previousDefaultIndex || randomOtherIndex === previousOtherIndex);

      const newBanners = [...defaultBanners];
      newBanners[randomDefaultIndex] = otherPartners[randomOtherIndex];
      setCurrentBanners(newBanners);

      previousDefaultIndex = randomDefaultIndex;
      previousOtherIndex = randomOtherIndex;
    }, 5000);

    return () => clearInterval(interval);
  }, [defaultBanners, otherPartners]);

  useEffect(() => {
    setCurrentBanners(defaultBanners); // Initialize with default banners
    return rotateBanners(); // Start the banner rotation
  }, [defaultBanners, rotateBanners]); // Empty dependency array to run once on mount

  return (
    <div className="partners-container"> {/* Updated class name */}
      {currentBanners.map((partner, index) => (
        <div key={index} className="partner dark-text"> {/* Updated class name */}
            <img src={partner.logoUrl} alt={partner.partnerName} />
        </div>
      ))}
    </div>
  );
};

export default Partners;
