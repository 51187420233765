// src/components/Leaderboard.js
import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';


function Leaderboard() {
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_ENV === 'development' ? process.env.REACT_APP_URL_DEVELOPMENT + ':' + process.env.REACT_APP_PORT_SERVER : process.env.REACT_APP_URL_SERVER}/api/leaderboard`);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        const data = await response.json();
        setLeaderboardData(data);
      } catch (error) { 
        console.error('Error fetching leaderboard data:', error.message);
      }
};

fetchLeaderboard();
// Periodically refresh the leaderboard data (e.g., every 60 seconds)
const refreshInterval = setInterval(fetchLeaderboard, 60000);

// Clean up the interval when the component unmounts
return () => clearInterval(refreshInterval);
}, []);
  
let rankNumber = 1;

  return (
<React.Fragment>
<h1>Leaderboard</h1>
<Table 
  dark
  hover
  //borderless	
  //striped
  //responsive
  size=""
  className="table-secondary text-secondary overflow-hidden caption-top" // caption-top <caption>Premium ADD here</caption>
  style={{ width: "95%", borderRadius: "15px" }}
  >
  <thead>
    <tr className="text-secondary">
      <th ><span role="img" aria-label="rank">#️⃣</span> Rank</th>
      <th><span role="img" aria-label="winner">🏅</span> Champion</th>
      <th><span role="img" aria-label="bitcoin">₿</span> Amount</th>
    </tr>
  </thead>
  <tbody>
    {leaderboardData.map((user) => (
      <tr key={user.userid}>
        <td>{rankNumber++}</td>
        <td>{user.userid}</td>
        <td><span role="img" aria-label="bitcoin">⚡</span>{user.totalSats} sat</td>
      </tr>
    ))}
  </tbody>
</Table>
</React.Fragment>
  );
}

export default Leaderboard;
