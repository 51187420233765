import React, { Component } from 'react';

export class AddToHomeScreenButton extends Component {
  constructor() {
    super();
    this.state = {
      deferredPrompt: null,
    };
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    let deferredPrompt;

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      deferredPrompt = e;
      this.setState({ deferredPrompt });
    });
  }

  onClick(e) {
    const { deferredPrompt } = this.state;
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
      }
      this.setState({ deferredPrompt: null });
    });
  }

  render() {
    const { deferredPrompt } = this.state;

    return (
      <span>
        {!!deferredPrompt && (
          <button className="btn-icon" onClick={this.onClick}><span role="img" aria-label="online">
                📲
              </span></button>
        )}
      </span>
    );
  }
}
