// src/components/Login.js
import React, { useState, useEffect } from 'react';
import { darkMode } from '../App.js';


function opacity(login) {
  const container = document.getElementById("userLogin");
  container.style.opacity = '0.6';
};

const Login = ({ onUpdateLightningAddress }) => {
  const [lightningAddress, setLightningAddress] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [isDarkMode, setDarkMode] = useState(false);

  // Function to toggle dark mode
  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode);
    darkMode();
  };

  // Check for existing user data in localStorage on component mount
  useEffect(() => {
    const storedLightningAddress = localStorage.getItem('lightningAddress');
    if (storedLightningAddress) {
      // If user data is found, set loggedIn to true
      setLoggedIn(true);
      setLightningAddress(storedLightningAddress);

      // Call the callback to update and log the lightningAddress in App.js
      onUpdateLightningAddress(storedLightningAddress);
    }
  }, [onUpdateLightningAddress]); // The empty dependency array ensures this effect runs only once on component mount

  const handleLogin = () => {
    // Perform authentication logic here (e.g., check account number against a database)
    // If authentication is successful, set the loggedIn flag to true.

    // For this example, we'll assume authentication is successful.
    setLoggedIn(true);

    // Store user information (e.g., lightningAddress) in localStorage
    localStorage.setItem('lightningAddress', lightningAddress);

    // Call the callback to update and log the lightningAddress in App.js
    onUpdateLightningAddress(lightningAddress);
  };

  const handleLogout = () => {
    // Clear the user's information from localStorage and set loggedIn to false
    localStorage.removeItem('lightningAddress');
    setLoggedIn(false);
    onUpdateLightningAddress('');
    setLightningAddress('');
  };

   // Disable the login button if the input is empty or contains only spaces
   // /^[a-zA-Z0-9!@#$%^&*()_+{}\\[\]:;<>,.?~\\-]+$/ --> /^[a-zA-Z0-9@.]*$/
   const isInputInvalid = !lightningAddress.trim() || lightningAddress.includes(' ') || lightningAddress.length > 128 || !/^[a-zA-Z0-9@.]*$/.test(lightningAddress);
  

  return (
    <div>
      {loggedIn ? (
        <div className="logout-conteiner">
          <span className="dark-text">Logged as </span>
          <span className="dark-text">
            <span role="img" aria-label="online">
              👻
            </span>{" "}
            {localStorage.getItem("lightningAddress")}
          </span>
          <br />
          <div className="logout-button">
            {isDarkMode ? (
              <button
                className="btn-icon"
                id="rabbitHole"
                onClick={toggleDarkMode}
              >
                <span role="img" aria-label="Support Bot">
                  🕳️
                </span>
              </button>
            ) : (
              <button
                className="btn-icon"
                id="rabbitHole"
                onClick={toggleDarkMode}
              >
                <span role="img" aria-label="Support Bot">
                  🐇
                </span>
              </button>
            )}
            <span className="space-button" />
            <button className="btn-icon" onClick={handleLogout}>
              <span role="img" aria-label="logout">
                🧨
              </span>
            </button>
          </div>
        </div>
      ) : (
        <div className="login-conteiner">
          <input
            type="text"
            id="userLogin"
            placeholder="⚡ Lightning address"
            value={lightningAddress}
            onChange={(e) => setLightningAddress(e.target.value)}
            onClick={() => opacity("userLogin")}
          />
          <br />
          <div className="login-button">
            <button
              className="btn-icon"
              onClick={handleLogin}
              disabled={isInputInvalid}
            >
              <span role="img" aria-label="online">
                🚀
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
