import React, { useState, useEffect } from 'react';

function ConnectionStatus({ socket }) {
  const [isConnected, setIsConnected] = useState(false);
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    socket.on('updateUserCount', (count) => {
      // console.log('Received user count:', count);
      setUserCount(count);
    });

    // Clean up event listeners when the component unmounts
    return () => {
    socket.off('updateUserCount');
   };
  }, [socket]);

  useEffect(() => {
    // Listen for the 'connect' and 'disconnect' events from the 'socket' prop
    socket.on('connected', () => {
      setIsConnected(true);
    });

    socket.on('disconnect', () => {
      setIsConnected(false);
    });

    // Clean up event listeners when the component unmounts
    return () => {
      socket.off('connected');
      socket.off('disconnect');
    };
  }, [socket]); // Ensure 'socket' is included as a dependency

  return (
    <div>
      {isConnected ? (
        <span role="img" aria-label="online" className='dark-text'>Connected 🟢 {userCount}</span> // 🟢 Online 🟢 Connected
      ) : (
        <span role="img" aria-label="offline" className='dark-text'>🔴 Disconnected</span> // 🔴 Ofline 🔴 Disconnected
      )}
    </div>
  );
}

export default ConnectionStatus;
