// src/components/Withdraws.js
import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap'


function Withdraws() {
  const [withdrawsData, setWithdrawsData] = useState([]);

  useEffect(() => {
    const fetchWithdraws = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_ENV === 'development' ? process.env.REACT_APP_URL_DEVELOPMENT + ':' + process.env.REACT_APP_PORT_SERVER : process.env.REACT_APP_URL_SERVER}/api/withdraws`);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        const data = await response.json();
        setWithdrawsData(data);
      } catch (error) { 
        console.error('Error fetching withdraws data:', error.message);
      }
};

fetchWithdraws();
// Periodically refresh the withdraws data (e.g., every 60 seconds)
const refreshInterval = setInterval(fetchWithdraws, 60000);

// Clean up the interval when the component unmounts
return () => clearInterval(refreshInterval);
}, []);
  
// let dateTime = 1; dateTime++

// Reverse the withdrawsData array
const reversedData = [...withdrawsData].reverse();

  return (
<React.Fragment>
<h1>Withdraws</h1>
<Table 
  size="sm"
  hover
  //borderless
  //responsive
  //striped	
  dark
  className="text-secondary rounded-xl overflow-hidden" // caption-top  <caption>Premium ADD here</caption>
  style={{ width: "95%", borderRadius: "15px" }}
  >
  <thead>
    <tr className="text-secondary" >
      <th><span role="img" aria-label="rank">⏳</span> Timestamp</th>
      <th><span role="img" aria-label="winner">🏆</span> Winner</th>
      <th><span role="img" aria-label="bitcoin">₿</span> Amount</th>
    </tr>
  </thead>
  <tbody>
    {reversedData.map((user) => (
      <tr key={user.userid}>
        <td>{new Date(user.unixtime * 1000).toISOString()}</td>
        <td className="text-dark">{user.userid}</td>
        <td><span role="img" aria-label="bitcoin">⚡</span>{user.sats} sat</td>
      </tr>
    ))}
  </tbody>
</Table>
</React.Fragment>
  );
}

export default Withdraws;
