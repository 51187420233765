import React from 'react';

const logoURL = process.env.REACT_APP_ENV === 'development' ? process.env.REACT_APP_URL_DEVELOPMENT + ':' + process.env.REACT_APP_PORT_CLIENT : process.env.REACT_APP_URL_CLIENT;

const contributors = [
  {
    logoUrl: `${logoURL}/images/sponsors/satshunt_io.svg`,
    linkUrl: "https://satshunt.io",
    contributorName: "satshunt_io",
  },
];

const Ads = () => {
  return (
    <div className="ads-container">
      {contributors.map((contributor, index) => (
        <div className="ad" key={index}>
            <a className='link' href={contributor.linkUrl} target="_blank" rel="noopener noreferrer">
                <img src={contributor.logoUrl} alt={contributor.contributorName} />
            </a>
        </div>
      ))}
    </div>
  );
};

export default Ads;
