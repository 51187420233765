import React from 'react';
import { css } from '@emotion/react';
import { BeatLoader } from 'react-spinners'; //ClipLoader size={50}

const override = css`
  display: block;
  margin: 0 auto;
`;

const LoadingBot = ({ IsUserLoggedIn, IsUserLoggedOut, IsAdmin, AdminLoggedOut}) => {

    let messageLoadingBot = '';

    if (IsAdmin) {

        if (IsUserLoggedOut) {
        messageLoadingBot = '₿ Satoshi is missing, please wait';
        } else if (IsUserLoggedIn) {
        messageLoadingBot = '₿ Satoshi is here, please join again';
        }
    } else if (AdminLoggedOut) {
    messageLoadingBot = '🛠️ Support Agent is missing, please wait';
    } else {
    messageLoadingBot = '🙌 Welcome, Satoshi! Please wait for the 🛠️ Support Agent to join 💡 Tip: Use ⌨TAB + ⌨Enter for 📩';
    }

  return (
    <div className='dark-text'>
    <p>{messageLoadingBot}</p>
    <p>
      <BeatLoader color={'#F6921a'} css={override} size={10} />
    </p>
    </div>
  );
};

export default LoadingBot;