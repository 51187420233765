import React, { useState } from 'react';
import QRCode from "react-qr-code";

const Accordion = () => {
  const [open1, setOpen1] = useState(null);
  const [open3, setOpen3] = useState(null);
  const [open0, setOpen0] = useState(null);
  const [open2, setOpen2] = useState(null);

  const handleClick1 = (item) => {
    setOpen1(open1 === item ? null : item);
    setOpen2(null);
    setOpen0(null);
  };

  const handleClick3 = (item) => {
    setOpen3(open3 === item ? null : item);
    setOpen1(null);
    setOpen0(null);
    setOpen2(null);
  };

  const handleClick0 = (item) => {
    setOpen0(open0 === item ? null : item);
    setOpen1(null);
    setOpen3(null);
    setOpen2(null);
  };
  
  const handleClick2 = (item) => {
    setOpen2(open2 === item ? null : item);
    setOpen1(null);
    setOpen3(null);
    setOpen0(null);
  };
  


  const accordionFAQ = [
    {
      title: 'How to know',
      content: (
        <div>
          <b>Probably Fair</b><br /><br />Our Lightning Lottery is 100% Provably Fair. Every game is verifiable through cryptographic hashes, providing you with the ultimate proof of fairness and transparency.<br /><br /><b>Close Source</b><br /><br />We believe in giving our players complete confidence in the integrity of our game. While our game's source code is currently closed, it's only due to our commitment to maintain the integrity of the game. We highly support open source projects and transparency. We are constantly exploring ways to share our code with the community in the future. Your trust and satisfaction are our top priorities.<br /><br /><b>Know Your Customer</b><br /><br />At our platform, we value your privacy and believe in minimal data collection. We do not require KYC information or store unnecessary user data. Your personal information is not our business - your enjoyment of the game is. Play with confidence, knowing your privacy is respected and protected.
        </div>
      ),
    },
    {
      title: 'How to play',
      content: (
        <div>
          Scan and pay the Lightning Invoice to get Ticket ID, paste it in the input field and click on search emoji. You can win Jackpot 5/36 only if you have 5 matching numbers, if you have 21 in your Tickets Numbers and you also have 21 in your Lucky Numbers you will win 4200 sats for each 21 that matched. 0 can be transformed to every number between 1 and 36 but it works for 21 only if you have 1 time in your Tickets Numres and Lucky Numbers
        </div>
      ),
    },
    {
      title: 'How to cheat',
      content: (
        <div>
          <b>Sorry, you can't cheat!</b><br /><br />In our Lightning Lottery we use Timechain, Ticket Numbers, SHA algorithms and Random Math functions for 100% Probably Fair Game.<br /><br /><b>Hackers</b><br /><br />Our Database is in separate servers and we only hold the wining tickets and they will be withdrawed, so you can't get usless information from there.<br /><br />We do not hold big amounts in our Lightning Wallets, we will check every winning and have the permition to ask for aditional information like transaction screen shots, etc. Also if the amount is to big for our nodes we will send the Reward Onchain with Lottery and Transaction fees paid the user.<br /><br />Do not send full LNURL in our Support Chat, you need to cut the last 12 letters or numbers for security reasons. Check for our Support Agent name to have the Lightning Emoji.
        </div>
      ),
    },
  ];

  const accordionReferral = [
    {
      title: 'Levels',
      content: (
        <div>
          <b>Ranking System</b><br /><br />
          Level 1 <span role="img" aria-label="spiral shell">🐚</span> Shell: Wins ≥ 0<br />
          Level 2 <span role="img" aria-label="shrimp">🦐</span> Shrimp: Wins ≥ 1<br />
          Level 3 <span role="img" aria-label="crab">🦀</span> Crab: Wins ≥ 5<br />
          Level 4 <span role="img" aria-label="lobster">🦞</span> Lobster: Wins ≥ 10<br />
          Level 5 <span role="img" aria-label="fish">🐟</span> Fish: Wins ≥ 21<br />
          Level 6 <span role="img" aria-label="squid">🦑</span> Squid: Wins ≥ 42, 3.25% fee, 4200 sats<br />
          Level 7 <span role="img" aria-label="octopus">🐙</span> Octopus: Wins ≥ 210, 1.63% fee, 21 000 sats<br />
          Level 8 <span role="img" aria-label="shark">🦈</span> Shark: Wins ≥ 420, 0.81% fee, 42 000 sats<br />
          Level 9 <span role="img" aria-label="whale">🐋</span> Whale: Wins ≥ 2100, 0.41% fee, 210 000 sats<br />
          Level 10 <span role="img" aria-label="spouting whale">🐳</span> Blue Whale: Wins ≥ 4200, 0.20% fee, 420 000 sats<br /><br />
          <span role="img" aria-label="warning sign">⚠️</span> Lottery Fee for Level 1-5 is 6.25%<br />
          <span role="img" aria-label="warning sign">⚠️</span> User must have ≥ 21 valid Referrs and valid Subs ID to use lower fee and extra bonus for the current level<br />
          <span role="img" aria-label="warning sign">⚠️</span> Extra Bonus will be added to every Month Mistery and Jackpot Wining<br />
          <span role="img" aria-label="warning sign">⚠️</span> Transaction Fee is not included in Lottery Fee<br />
        </div>
      ),
    },
    {
      title: 'Referrer',
      content: (
        <div>
        <b>To be a Referrer</b><br /><br />
        Level 5: Fish<br />
        Valid Subscription ID / Subs ID<br /><br />
        <b>Referrer Earn</b><br /><br />
        <b>For each Referee</b><br />
        1 chance for Month Mistery - 420 000 sats<br /><br />
        <b>For every five Referee</b><br />
        21 000 sats<br />
        50% lower Lottery Fees for the days that left from Subscription
        </div>
      ),
    },
    {
      title: 'Referee',
      content: (
        <div>
          1. Minimum Fish Level 5
          2. Valid Subscription ID
          Referre Earn:
          - 1 chance for month 420 000 sats Mistery
          - 21 000 sats
          - 50% lower Lottery Fees for the days that left from his Subscription
        </div>
      ),
    },
  ];

  const accordionProducts = [
  {
    title: 'Application',
    content: (
      <div>
        Bitcoin Lottery Game. Powered by Lightning Network. The game is simple you need to choose five numbers between 1 and 36. You cannot choose 0, but you can have it, it is our Magic Number. 21 is our Lucky Number, if you decide to have it in your Ticket Numbers and you have it in your Lucky Numbers you win 4200 sats
      </div>
    ),
  },
  {
    title: 'Subscription',
    content: (
      <div>
        Premium 24/7 Support for 30 days. Lower tax fees from 13% to 6.5% if you win the Jackpot. Dashboard with Total winning sats and Subscribtion expiration time.
      </div>
    ),
  },
  {
    title: 'Contribition',
    content: (
      <div>
        <div>
        Sponsor, Partner, Friend or Contributor it is your choice. If you decide to become a sponsor you will get your logo with colors and link to your web. If you preffer to be a Partner you will get your logo without color and without link and you need to do the same for us on your web. We can be friends the only thing is you to love Bitcoin and we to like you, you will get your logo in dynamic change without color and without link. If you like us and want to support what we do you can contribute to our project with a custom donation
        </div>
        <div className='info-qr-code'>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', overflowX: 'hidden', paddingTop: '21px' }}>
      <div 
        style={{
          height: "auto",
          margin: "0 auto",
          maxWidth: 100,
          width: "100%",
          borderRadius: "15px",
          border: "2px solid #161616",
          background: "#848383",
        }}
      >
        <a href={process.env.REACT_APP_LNURL_LIGHTNING} target="_blank" rel="noopener noreferrer">
        <QRCode
          size={256}
          style={{
            padding: "10px",
            height: "auto",
            maxWidth: "100%",
            width: "100%",
            borderRadius: "15px",
          }}
          value={process.env.REACT_APP_LIGHTNING}
          fgColor="#161616"
          bgColor="#4D4D4D"
          viewBox={`0 0 256 256`}
        />
        </a>
        <div style={{ textAlign: 'center', marginTop: '5px', overflowX: 'hidden', color: '#4D4D4D' }}>
      <span role="img" aria-label="lightning-emoji">⚡</span> Lightning Address: {process.env.REACT_APP_LIGHTNING}
    </div>
    </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', overflowX: 'hidden', paddingTop: '21px' }}>
      <div
        style={{
          height: "auto",
          margin: "0 auto",
          maxWidth: 100,
          width: "100%",
          borderRadius: "15px",
          border: "2px solid #161616",
          background: "#848383",
        }}
      >
        <a href={process.env.REACT_APP_URL_ONCHAIN} target="_blank" rel="noopener noreferrer">
        <QRCode 
          size={256}
          style={{
            padding: "10px",
            height: "auto",
            maxWidth: "100%",
            width: "100%",
            borderRadius: "15px",
          }}
          value={process.env.REACT_APP_ONCHAIN}
          fgColor="#161616"
          bgColor="#4D4D4D"
          viewBox={`0 0 256 256`}
        />
        </a>
        <div style={{ textAlign: 'center', marginTop: '5px', overflowX: 'hidden', color: '#4D4D4D' }}>
           <span role="img" aria-label="onchain-emoji">⛓️</span> Onchain Address: {process.env.REACT_APP_ONCHAIN}
          </div>
      </div>
    </div>
  </div>
</div>
    ),
  },
];


 const accordionExplore = [
  {
    title: 'Pricing',
    content: (
      <div>
        Our Lottery game has a minimum limit of 210 sats and a maximum limit of 2,100,000 sats to play with. When someone wins the Jackpot, we take a 13% tax, but if you have a Subscribtion, you will be charged 6.5%. Our Subs ID is 21,000 sats for 30 days. Being a sponsor costs 210,000 sats per 30 days. To be a Partner is a win-win. To be a friend is free if you love Bitcoin and we like you.
      </div>
    ),
  },
  {
    title: 'Roadmap',
    content: (
      <div>
        Custom play with Onchain transaction for over 2,100,000 sats to 2.1 BTC. New Bitcoin Lotto Game with 3 numbers from 1 to 18.
      </div>
    ),
  },
  {
    title: 'Contact us',
    content: (
      <div>
        <span role="img" aria-label="email-emoji">📨</span> support<span role="img" aria-label="monkey-emoji">🙈</span>21nmill<span role="img" aria-label="orange-emoji">🟠</span>io <span role="img" aria-label="key-emoji">🔑</span> PGP: A015 AB21 DCC4 AF1C or on our <span role="img" aria-label="support-emoji">🛠️</span> Support Chat /requaries Subscribtion ID/
      </div>
    ),
  },
];

  return (
    <div className='info-wrapper'>
    <div>
        <div className='info-conteiner'>
        <h1 style={{ padding: '2px', fontSize: '16px' }}>FAQ</h1>
      {accordionFAQ.map((item, index) => (
        <div key={index}>
          <button className='accordion-btn' onClick={() => handleClick1(index)}>{item.title}</button>
          {open1 === index && <div className='infoText-conteiner'>{item.content}</div>}
        </div>
      ))}
      </div>
      <div className='info-conteiner'>
        <h1 style={{ padding: '2px', fontSize: '16px' }}>Referral</h1>
      {accordionReferral.map((item, index) => (
        <div key={index}>
          <button className='accordion-btn' onClick={() => handleClick3(index)}>{item.title}</button>
          {open3 === index && <div className='infoText-conteiner'>{item.content}</div>}
        </div>
      ))}
      </div>
        <div className='info-conteiner'>
        <h1 style={{ padding: '2px', fontSize: '16px' }}>Products</h1>
      {accordionProducts.map((item, index) => (
        <div key={index}>
          <button className='accordion-btn' onClick={() => handleClick0(index)}>{item.title}</button>
          {open0 === index && <div className='infoText-conteiner'>{item.content}</div>}
        </div>
      ))}
      </div>
      <div className='info-conteiner'>
      <h1 style={{ padding: '2px', fontSize: '16px' }}>Explore</h1>
      {accordionExplore.map((item, index) => (
        <div key={index}>
          <button className='accordion-btn' onClick={() => handleClick2(index)}>{item.title}</button>
          {open2 === index && <div className='infoText-conteiner'>{item.content}</div>}
        </div>
      ))}
      <p style={{ paddingBottom: '10px', fontSize: '16px' }} className='dark-text multiline-text multi-line'><span role="img" aria-label="heart-emoji">Copyright © 2023 Created with ❤️</span><br />21nmill.io. All rights reserved</p>
      </div>
    </div>
  </div>
  );
};

export default Accordion;