import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import ConnectionStatus from './components/ConnectionStatus'; 
import Login from './components/Login'; 
import './index.css';
import LoadingSpinner from './components/LoadingSpinner';
import Leaderboard from './components/Leaderboard';
import Withdraws from './components/Withdraws';
import Chat from './components/Chat';
import Ads from './components/Ads';
import Partners from './components/Partners';
import { AddToHomeScreenButton } from "./components/HomeScreen";
import Accordion from "./components/Information";
import QRCode from "react-qr-code";


const jwt = require('jsonwebtoken');
const token = jwt.sign(
  {
    username:
      localStorage.getItem("lightningAddress") !== null
        ? localStorage.getItem("lightningAddress")
        : "satoshi@21nmill.io",
  },
  process.env.REACT_APP_JWT_SECRET
);

const serverURL = process.env.REACT_APP_ENV === 'development' ? process.env.REACT_APP_URL_DEVELOPMENT + ':' + process.env.REACT_APP_PORT_SERVER : process.env.REACT_APP_URL_SERVER;

const socket = io(serverURL, {
  autoConnect: true,
  secure: true,
  query: { token },
  withCredentials: true,
  transports: ["websocket"]
});

// Socket.io
// ---------

// Socket.io connection
socket.on('connect', () => {
  console.log('🍐 Connected ');
});
// Socket.io error handling
socket.on('error', (error) => {
  console.error('🚨 WebSocket Error: ', error);
});

// ---------
// Socket.io

function opacity(Id) {
  const container = document.getElementById("ticketId");
  container.style.opacity = '0.6';
};

// 💡 &&  🔌  || 🐇 && 🕳️
export function darkMode() {
  var element = document.body;
  element.classList.toggle("dark-mode");
};

// Time limiting with custom interval
let lastSendTime = 0;
const sendInterval = 21000; // 21 second interval 

function copyTextToClipboard(text) {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("📋 Copied To Clipboard");
        //console.log("Text copied to clipboard.");
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
      });
  } else {
    // Fallback for browsers that do not support the Clipboard API
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    alert("📋 Copied To Clipboard");
  }
}

function App() {
  const [ticketId, setTicketId] = useState("");
  const [lastFiveLogs, setLastFiveLogs] = useState([]);
  const [inputError, setInputError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [timeError, setTimeError] = useState("");
  const [lightningaddress, setLightningAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [winnerNotify, setWinnerNotify] = useState("");
  const [isLotteryWon, setLotteryWon] = useState(false);

  // Updated copyListToClipboard function
  const copyListToClipboard = () => {
    const listText = lastFiveLogs.join("\n");
    copyTextToClipboard(listText);
  };

  useEffect(() => {
    async function fetchJackpot() {
      try {
        const response = await fetch(`${serverURL}/api/jackpot`);
        //console.log(response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setLotteryWon(data.isLotteryWon);
        //console.log(data.isLotteryWon);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchJackpot();
    // Periodically refresh the withdraws data (e.g., every 60 seconds)
    const refreshInterval = setInterval(fetchJackpot, 60000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(refreshInterval);
  }, []);

  useEffect(() => {
    socket.on("winner", (message) => {
      // console.log('Winner:', message);
      setWinnerNotify(message);
      // Set a timeout to clear the winner message after 21 seconds
      setTimeout(() => {
        setWinnerNotify("");
      }, 21000); // 21 seconds
    });
  }, []);

  /*const [connectedUsers, setConnectedUsers] = useState(0);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await fetch(`process.env.REACT_APP_URL_`);
        if (response.ok) {
          const data = await response.json();
          setConnectedUsers(data.connectedUsers);
        } else {
          console.error('Failed to fetch connected users');
        }
      } catch (error) {
        console.error('Error fetching connected users:', error);
      }
    };

    fetchUserCount();
  }, []);*/

  // This function will be passed as a prop to Login and will log lightningaddress
  const logLightningAddress = (lightningaddress) => {
    const isInputInvalid = !lightningaddress.trim() || lightningaddress.includes(' ') || lightningaddress > 128 || !/^[a-zA-Z0-9@.]*$/.test(lightningaddress);
    //console.log(isInputInvalid);
    //console.log(lightningaddress);

  if (isInputInvalid) {
    //console.log("Invalid User ID");
    setLightningAddress('');
  } else {
    // console.log('Lightning Address:', lightningaddress);
    setLightningAddress(lightningaddress);
  }
  };

  // Listen for the "error" event from the server
  socket.on("error", (errorMessage) => {
    setErrorMessage(errorMessage); // Set the received errorMessage in the state
    setLastFiveLogs([]);
    setIsLoading(false);
  });

  // Function to clear lastFiveLogs
  const clearLastFiveLogs = () => {
    setLastFiveLogs([]);
  };

  useEffect(() => {
    // Show lastFiveLogs to user
    socket.on("lastFiveLogs", (logs) => {
      if (errorMessage) {
        // If errorMessage is not empty, show it and clear lastFiveLogs
        setErrorMessage(errorMessage);
        setLastFiveLogs([]);
      } else {
        // If errorMessage is empty, show lastFiveLogs and set isLoading to false
        setLastFiveLogs(logs);
        setErrorMessage("");
        setIsLoading(false);
      }
    });
  }, [errorMessage]);

  useEffect(() => {
    // Enable/disable the button based on input validation
    setIsButtonDisabled(!ticketId.trim() || ticketId.includes(" "));
  }, [ticketId]);

  const handleSendData = () => {
    // Regular expression for SHA-256 hash
    const sha256Pattern = /^[0-9A-Fa-f]{64}$/;

    // Regular exoression for Time and Date
    const currentTime = new Date().getTime();

    if (
      ticketId.includes(" ") ||
      !ticketId.trim() ||
      !sha256Pattern.test(ticketId)
    ) {
      setInputError(true);
      setErrorMessage(false);
      setLastFiveLogs([]);
      setTimeError(false);
      setIsLoading(false);
      return;
    }

    if (currentTime - lastSendTime < sendInterval) {
      setTimeError(
        "Rate limit exceeded, please wait ( 21s ) before sending another Ticket ID"
      );
      setTicketId("");
      setInputError(false);
      setErrorMessage(false);
      setLastFiveLogs([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    // Send the data to the server
    socket.emit("sendData", ticketId, lightningaddress);

    // Clear the form fields after sending data
    setTicketId("");
    setInputError(false);
    setErrorMessage(false);
    setTimeError("");
    setLastFiveLogs([]);

    // Update the last send time
    lastSendTime = currentTime;
  };

  //console.log(lastFiveLogs); // [.]

  return (
    <div className="app">
      <div>
        <ConnectionStatus socket={socket} />
      </div>
      <div>
        <Login onUpdateLightningAddress={logLightningAddress} />
      </div>
      <div>
        <a className="link" href={process.env.REACT_APP_LNURL_TICKET} target="_blank" rel="noopener noreferrer">
        <QRCode 
        size={150}
        style={{
          padding: "10px",
          height: "auto",
          maxWidth: "100%",
          }}
          className='qr-code' 
          value={process.env.REACT_APP_URL_TICKET}
          fgColor="#161616"
          bgColor="#4D4D4D"
          viewBox={`0 0 150 150`} />
      </a>
      </div>
      <div>
        <h1>
          {winnerNotify ? (
            winnerNotify
          ) : (
            <span role="img" aria-label="21">
              2️⃣1️⃣nmill.io
            </span>
          )}
        </h1>
      </div>
      <div>
        {isLotteryWon ? (
          <h1>
            JACKPOT WON! Our Support Agent will soon make the Onchain
            transaction to the luckiest person in the world. Stay tuned as we
            prepare to restart the game
          </h1>
        ) : (
          <input
            onClick={() => opacity("ticketId")}
            id="ticketId"
            type="text"
            value={ticketId}
            placeholder="🔖 TICKET 🆔"
            onChange={(e) => setTicketId(e.target.value)}
          />
        )}
      </div>
      <div className="search-button">
        <button
          className="btn-icon"
          onClick={handleSendData}
          disabled={isButtonDisabled}
        >
          <span role="img" aria-label="Check">
            🔎
          </span>
        </button>
        <span className="space-button" />
        <AddToHomeScreenButton />
      </div>
      <div className="game-container">
        <div className="error-container">
          {inputError && (
            <p className="orange-text">
              <span role="img" aria-label="error">
                ⚠️
              </span>{" "}
              The 'provide' field must be a valid Ticket ID and cannot contain
              spaces or be empty
            </p>
          )}
          {errorMessage && (
            <p className="orange-text">
              <span role="img" aria-label="error">
                ⚠️
              </span>{" "}
              {errorMessage}
            </p>
          )}
          {timeError && (
            <p className="orange-text">
              <span role="img" aria-label="error">
                ⚠️
              </span>{" "}
              {timeError}
            </p>
          )}
        </div>
        {isLoading ? (
          <div className="loading-container">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            {lastFiveLogs.length > 0 && (
              <div className='app-conteiner'>
                <button className="btn-icon" onClick={clearLastFiveLogs}>
                  <span role="img" aria-label="Check">
                    🔄
                  </span>
                </button>
                <span className="app-buttons" />
                <button className="btn-icon" onClick={copyListToClipboard}>
                  <span role="img" aria-label="Copy">
                    📋
                  </span>
                </button>
              </div>
            )}
            <ul className="remove-bullets">
              {lastFiveLogs.map((log, index) => (
                <li className="multiline-text multi-line" key={index}>
                  {log.includes("LNURL") && !log.includes("1 / 1") ? (
                    <div className="multiline-text multi-line">
                      {log}
                      <div className="qr-code-container multiline-text multi-line">
                        <a
                          href={`lightning:${log
                            .replace("LNURL:", "")
                            .replace(/[^A-Za-z0-9]/g, "")}`}
                            target="_blank" rel="noopener noreferrer"
                        >
                          <QRCode
                            size={256}
                            className="qr-code-img multiline-text multi-line"
                            viewBox="0 0 256 256"
                            value={`${log
                              .replace("LNURL:", "")
                              .replace(/[^A-Za-z0-9]/g, "")}`}
                          />
                        </a>
                      </div>
                    </div>
                  ) : (
                    log
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div>
        <Leaderboard />
      </div>
      <div>
        <Withdraws />
      </div>
      <div>
        <div className="partners-name">
          <h1>Partners</h1>
        </div>
        <Partners />
        <div className="ads-name">
          <h1>Sponsors</h1>
        </div>
        <Ads />
      </div>
      <div className="chat-conteiner">
        <Chat
          socket={socket}
          token={token}
          lightningaddress={lightningaddress}
        />
      </div>
      <div>
        <Accordion />
      </div>
    </div>
  );
}

export default App;