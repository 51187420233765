// Chat.js
import React, { useState, useEffect, useRef } from 'react';
import LoadingBot from './LoadingBot';
import { Howl } from 'howler';
import LoadingSpinner from './LoadingSpinner';
import QRCode from "react-qr-code";

const MAX_MESSAGE_LENGTH = 256;
const MAX_MESSAGES_COUNT = 10; 

function opacityRoomId() {
  const containerRoomId = document.getElementById("roomId");
  containerRoomId.style.opacity = '0.6';
};

function opacityMessageId() {
  const containerMessageId = document.getElementById("messageId");
  containerMessageId.style.opacity = '0.6';
  
};

const messageSound = new Howl({
  src: ["/message.mp3"],
  html5: true,
  volume: 1, // Set the volume to 1 for full volume
});

const roomSound = new Howl({
  src: ["/room.mp3"],
  html5: true,
  volume: 1, // Set the volume to 1 for full volume
});

// Support Users
const supportAgent = '⚡ support@21nmill.io';
const chatBot = '👾 Support Bot';

// Time limiting with custom interval
let lastSendTime = 0;
const sendInterval = 21000; // 21 second interval


function Chat({ socket, lightningaddress }) {
  const [roomId, setRoomId] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [joinedRoom, setJoinedRoom] = useState(false);
  const [isButtonRoomDisabled, setIsButtonRoomDisabled] = useState(true);
  const [isButtonMessageDisabled, setIsButtonMessageDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [activeRooms, setActiveRooms] = useState('');
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userLoggedOut, setUserLoggedOut] = useState(false);
  const [adminLoggedOut, setIsAdminLoggedOut] = useState(false);
  const [isVolumeEnabled, setVolumeEnabled] = useState(true);
  const [typing, setTyping] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [typingUser, setTypingUser] = useState('');
  const [errorSubs, setErrorSubs] = useState('');
  const [statusSubs, setStatusSubs] = useState('');
  const [satsSubs, setSatsSubs] = useState('');
  const [timeError, setTimeError] = useState("");
  const [inputError, setInputError] = useState(false);
  const [isLoadingSubs, setIsLoadingSubs] = useState(false);
  const [levelsSubs, setLevelsSubs] = useState('');

  useEffect(() => {
    // Listen for the "levelsSubscribtion" event from the server
  socket.on('levelsSubscribtion', (levelsSubs) => {
    setLevelsSubs(levelsSubs); 
    //console.log(levelsSubs);
  });
  // Clean up the socket event listener when the component unmounts
    return () => {
      socket.off('levelsSubscribtion');
    };
  }, [socket]);
  
  useEffect(() => {
    // Listen for the "satsSubscribtion" event from the server
  socket.on('satsSubscribtion', (satsSubs) => {
    setSatsSubs(satsSubs);
    //console.log(satsSubs);
  });
  // Clean up the socket event listener when the component unmounts
    return () => {
      socket.off('satsSubscribtion');
    };
  }, [socket]);
  
  useEffect(() => {
    // Listen for the "error" event from the server
  socket.on('statusSubscribtion', (statusSubs) => {
    setStatusSubs(statusSubs); // Set the received errorSubs in the state
    //console.log(statusSubs);
  });
  // Clean up the socket event listener when the component unmounts
    return () => {
      socket.off('statusSubscribtion');
    };
  }, [socket]);

  useEffect(() => {
    // Listen for the "error" event from the server
  socket.on('errorSubscribtion', (errorSubs) => {
    setErrorSubs(errorSubs); // Set the received errorSubs in the state
    //console.log(errorSubs);
    setTimeError(false);
    setInputError(false);
    setIsLoadingSubs(false);
  });
  // Clean up the socket event listener when the component unmounts
    return () => {
      socket.off('errorSubscribtion');
    };
  }, [socket]);
  

  const toggleVolume = () => {
    if (isVolumeEnabled) {
      messageSound.volume(0); // Mute the sound
      roomSound.volume(0); // Mute the sound
    } else {
      messageSound.volume(1); // Enable full volume
      roomSound.volume(1); // Enable full volume
    }
    setVolumeEnabled(!isVolumeEnabled);
  };

  // Scroll to the bottom whenever messages change
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    // Listen for the 'adminActive' event from the server
    socket.on('userLoggedIn', () => {
      setIsLoading(true);
      //setMessages([]);
      setUserLoggedIn(true);
      setUserLoggedOut(false);
      setJoinedRoom(true);
      setIsLoadingSubs(false);
    });

    // Clean up the socket event listener when the component unmounts
    return () => {
      socket.off('userLoggedIn');
    };
  }, [socket]);

  useEffect(() => {
    // Listen for the 'adminActive' event from the server
    socket.on('userLoggedOut', () => {
      setIsLoading(true);
      //setMessages([]);
      setUserLoggedOut(true);
    });

    // Clean up the socket event listener when the component unmounts
    return () => {
      socket.off('userLoggedOut');
    };
  }, [socket]);

  useEffect(() => {
    // Listen for the 'adminActive' event from the server
    socket.on('activeAdmins', () => {
      setIsAdmin(true); // Set isAdmin to true when admin is active
    });

    // Clean up the socket event listener when the component unmounts
    return () => {
      socket.off('activeAdmins');
    };
  }, [socket]);

  useEffect(() => {
    // Listen for the 'activeRooms' event from the server
    socket.on('activeRooms', (rooms) => {
      setActiveRooms(rooms);
    });

    // Clean up the socket event listener when the component unmounts
    return () => {
      socket.off('activeRooms');
    };
  }, [socket]);

  useEffect(() => {
    // Listen for the 'activeRooms' event from the server
    socket.on('receivedMessage', () => {
      messageSound.play();
    });

    // Clean up the socket event listener when the component unmounts
    return () => {
      socket.off('receivedMessage');
    };
  }, [socket]);

  useEffect(() => {
    // Listen for the 'activeRooms' event from the server
    socket.on('receiverRoom', () => {
      roomSound.play();
    });

    // Clean up the socket event listener when the component unmounts
    return () => {
      socket.off('receiverRoom');
    };
  }, [socket]);
   
  useEffect(() => {
  // Add an event listener for 'adminLoggedIn'
socket.on('adminLoggedIn', () => {
  // This code will run when the admin logs in
  //console.log('Admin is logged in');
  setJoinedRoom(true);
  // Set isLoading to false
  setIsLoading(false);
  setIsLoadingSubs(false);
});
 // Clean up the socket event listener when the component unmounts
    return () => {
      socket.off('adminLoggedIn');
    };
  }, [socket]);

  useEffect(() => {
socket.on('adminLoggedOut', () => {
  // This code will run when the admin logs in
  //console.log('Admin is logged out');
  
  setIsLoading(true);
  setIsAdminLoggedOut(true);
  //setMessages([]);
});
// Clean up the socket event listener when the component unmounts
    return () => {
      socket.off('adminLoggedOut');
    };
  }, [socket]);

  useEffect(() => {

    // Enable/disable the button based on input validation
    setIsButtonRoomDisabled(!roomId.trim() || roomId.includes(' ')); 
  }, [roomId]);

  useEffect(() => {

  // Enable/disable the button based on input validation
  setIsButtonMessageDisabled(!message.trim() || !/^[a-zA-Z0-9!@\\[\]_:;,.? -]+$/.test(message));
}, [message]);

socket.on('message', (newMessage) => {

   // Check if newMessage is a string; if not, convert it to one
   if (typeof newMessage !== 'string') {
    newMessage = String(newMessage);
  }

  // Trim the message to the maximum allowed length
  newMessage = newMessage.slice(0, MAX_MESSAGE_LENGTH);

  // Check if the new message is identical to the last message (potential spam)
  if (messages.length > 0 && messages[messages.length - 1] === newMessage) {
    return; // Ignore the message to prevent spam
  }

  // Check if adding a new message would exceed the maximum messages count
  if (messages.length >= MAX_MESSAGES_COUNT) {
    // Remove the oldest message to make space for the new one
    const updatedMessages = messages.slice(1);
    setMessages([...updatedMessages, newMessage]);
  } else {
    // Just add the new message if there is still room
    setMessages([...messages, newMessage]);
  }
  //setMessage(''); // Clear all inputs
});

useEffect(() => {
socket.on('typingStartServer', (usernameId) => {
  setTyping(true);
  setTypingUser(usernameId);
  //console.log('typing ...');
});
// Clean up the socket event listener when the component unmounts
    return () => {
      socket.off('typingStartServer');
    };
  }, [socket]);

useEffect(() => {
socket.on('typingStopServer', () => {
  setTyping(false);
  setTypingUser('');
  //console.log('typing ...');
});
// Clean up the socket event listener when the component unmounts
    return () => {
      socket.off('typingStopServer');
    };
  }, [socket]);

  const handleJoinRoom = () => {

    // Regular expression for SHA-256 hash
    const sha256Pattern = /^[0-9A-Fa-f]{64}$/;

    // Regular exoression for Time and Date
    const currentTime = new Date().getTime();

    if (
     roomId.includes(" ") ||
     !roomId.trim() ||
     !sha256Pattern.test(roomId)
   ) {
     setInputError(true);
     setErrorSubs(false);
     setJoinedRoom(false);
     setTimeError(false);
     setIsLoadingSubs(false);
     return;
   }

   if (currentTime - lastSendTime < sendInterval) {
     setTimeError(
       "Rate limit exceeded, please wait ( 21s ) before sending another Subs ID"
     );
     setRoomId("");
     setInputError(false);
     setErrorSubs(false);
     setJoinedRoom(false);
     setIsLoadingSubs(false);
     return;
   }

   setIsLoadingSubs(true);

    // Audio when user join room
    //roomSound.play();

    // Socket Join 
    setTimeout(() => {
      socket.emit('joinRoom', roomId);
      }, 5000); // 5,000 milliseconds = 5 seconds
    
    
    // User has joined the room
    //setJoinedRoom(true); 
    //setIsLoading(true);

    // Update the last send time
    lastSendTime = currentTime;
  };

  const handleLeaveRoom = () => {

    // Audio when user leave room
    //roomSound.play();

    socket.emit('leaveRoom', roomId);
    // setMessages([]);

    // Cleaning
    setRoomId("");
    setJoinedRoom(false);
    setTimeError(false);
    setErrorSubs(false);
    setInputError(false);
    setIsLoadingSubs(false);
  };

  const handleSendMessage = () => {

    // Audio when user send message
    //messageSound.play(); 

    // Send the data to the server
    socket.emit('sendMessage', roomId, message, lightningaddress);

    // Clear the form fields after sending data
    setMessage('');

    // Message Input Focus
    messageInputRef.current.focus();
  };

  useEffect(() => {
    return () => {
      // Unload the components when unmounts
      messageSound.unload(); 
      roomSound.unload();
    };
  }, []);


  function handleInput (e) {
    setMessage(e.target.value);
    socket.emit('typingStart', roomId, lightningaddress);

    if (typingTimeout) clearTimeout(typingTimeout)

    setTypingTimeout(setTimeout(() => {
      socket.emit('typingStop', roomId);
      //console.log('timeout');
    }, 500));
  }

  // Create a ref for the input element
  const messageInputRef = useRef(null);
  const containerRef = useRef(null);

  return (
    <div>
    {isAdmin ? (
        <div>
          <div>
          <h1>Support Dashboard</h1>
          </div>
          {Object.keys(activeRooms).length === 0 ? (
      <p style={{color: "#848383"}}><span role="img" aria-label="Support Bot">👾</span> Support Bot: <span role="img" aria-label="Support Bot">👋</span> Agent, no open requests right now</p>
    ) : (
      <ul className="remove-bullets">
        {Object.keys(activeRooms).map((roomId) => (
          <li className="multiline-text multi-line" key={roomId}>
            <span role="img" aria-label="sosid">🚨</span> {roomId}
            <ul className="remove-bullets">
              {Object.keys(activeRooms[roomId]).map((socketId) => (
                <li className="multiline-text multi-line" key={socketId}>
                  <span role="img" aria-label="users">👀 </span> {activeRooms[roomId][socketId].usernameId}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    )}
    </div>
      ) : (
        <div className='qrcode-chat-conteiner'>
        <div>
        <a className="link" href={process.env.REACT_APP_LNURL_SUBS} target="_blank" rel="noopener noreferrer">
        <QRCode 
        size={150}
        style={{
          padding: "10px",
          height: "auto",
          maxWidth: "100%",
          }}
          className='qr-code' 
          value={process.env.REACT_APP_URL_SUBS}
          fgColor="#161616"
          bgColor="#4D4D4D"
          viewBox={`0 0 150 150`} />
      </a>
        </div>
        <div>
        <h1>Support Chat</h1>
        </div>
        </div>
      )}
      <div className='errorSubs-container'>
      {isLoadingSubs ? (
          <div className="loading-container">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
      {!joinedRoom ? (
        <div>
          <input
            type="text"
            placeholder="🛠️ Subscription 🆔"
            id="roomId"
            value={roomId}
            onChange={(e) => setRoomId(e.target.value)}
            onClick={() => opacityRoomId("roomId")}
          />
          <div className='sos-button'>
          <button className="btn-icon" disabled={isButtonRoomDisabled} onClick={handleJoinRoom}><span role="img" aria-label="sos-emoji">🆘</span>
          </button>
          </div>
          <div className='errorSubs-container'>
          {inputError && (
            <p className="orange-text">
              <span role="img" aria-label="error">
                ⚠️
              </span>{" "}
              The 'provide' field must be a valid Subs ID and cannot contain
              spaces or be empty
            </p>
          )}
          {errorSubs && (
      <p className="orange-text">
        <span role="img" aria-label="error">
          ⚠️
        </span>{" "}
        {errorSubs}
      </p>
    )}
    {timeError && (
            <p className="orange-text">
              <span role="img" aria-label="error">
                ⚠️
              </span>{" "}
              {timeError}
            </p>
          )}
    </div>
        </div>
      ) : (
        <div>
          <div className='conteiner'>
          <div className="multiline-text multi-line statusSubs-container">
            {statusSubs}
            </div>
            <div className="multiline-text multi-line statusSubs-container">
            {satsSubs}
            </div>
            <div className="multiline-text multi-line statusSubs-container">
            {levelsSubs}
            </div>
          <button className="btn-icon" onClick={toggleVolume}>
          {isVolumeEnabled ? <span role="img" aria-label="volume-enable">🔊</span> : <span role="img" aria-label="volume-disable">🔇</span>}
          </button>
          <span className="space-button" />
          <button className="btn-icon" onClick={handleLeaveRoom}><span role="img" aria-label="rank">🚪</span></button>
          </div>
          <div className='messages' ref={containerRef}>
  {messages.map((msg, index) => {
    let className = '';
    if (msg.includes(chatBot) || msg.includes(chatBot, supportAgent)) {
      className = "support-bot-message"; 
    } else if (msg.includes(supportAgent)) {
      className = "support-agent-message"; 
    } else {
      className = "message";
    }
    return (
      <p className={className} key={index}>{msg}</p>
    );
  })}
</div>
          {isLoading ? (
         <LoadingBot IsUserLoggedIn={userLoggedIn} IsUserLoggedOut={userLoggedOut} IsAdmin={isAdmin} AdminLoggedOut={adminLoggedOut}/>
      ) : (
        <div className='chat-input'>
          {typing && <span className='dark-text'>{typingUser} typing...</span>}
          <br />
          <input
            type="text"
            id="messageId"
            placeholder="📌 Message"
            value={message}
            onChange={handleInput}
            onClick={() => opacityMessageId("messageId")}
            ref={messageInputRef}
          />
          <div className='message-button'>
          <button className="btn-icon" disabled={isButtonMessageDisabled} onClick={handleSendMessage}><span role="img" aria-label="rank">💬</span></button>
          </div>
        </div>
      )}
      </div>
      )}
    </div>
    )}
  </div>
</div>
  );
}

export default Chat;
